

$(document).ready(function() {
  window.scrollTo(0,0);

  //FAQ Accordian
  $(function() {
    $('.accordion').find('.accordion__title').click(function(){
      // Adds active class
      $(this).toggleClass('active');
      // Expand or collapse this panel
      $(this).next().slideToggle('fast');
      // Hide the other panels
      $('.accordion__content').not($(this).next()).slideUp('fast');
      // Removes active class from other titles
      $('.accordion__title').not($(this)).removeClass('active');    
    });
  });
  
  //mobile navigation
  $('.customMenuParent .SubMenuController').click(function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('active');
    // $(this).next().slideToggle();
  })

// Preloader
  $(window).on('load', function(){
    $("#preloaderInner").fadeOut();
    $("#preloaderWrapper").delay(100).fadeOut("slow");
    $("main").addClass('mainShow');
    console.log("Preloader");
  });

  // Mobile Filter Sidebar
  $('#FilterButton').click(function(e) {
    e.preventDefault();
    $('#FilterSidebar').toggleClass('openSidebar');
    $('.ProfessionalSidebar').toggleClass('openSidebar');
  })
  // Close mobile filter
  $('.CloseSidebar').click(function(e) {
    e.preventDefault();
    $('#FilterSidebar').removeClass('openSidebar');
    $('.ProfessionalSidebar').removeClass('openSidebar');
  })

	 //-- Sticky Sidebar
  function sticky_sidebar(topValue){
    $(".StickySidebar").stick_in_parent({
      offset_top: topValue
    });
    $(".ProfessionalSidebar").stick_in_parent({
      // offset_top: 132
    })
  }

  var window_width = $(window).width();
  // console.log("Window width", window_width);

  if(window_width > 1099){
    sticky_sidebar(250);
  } else if(window_width < 992){
      $(".StickySidebar").trigger("sticky_kit:detach");
      $(".ProfessionalSidebar").trigger("sticky_kit:detach");
  } else{
    sticky_sidebar(312);
  }

	// selectize
	$('#multi-select').selectize({
    plugins: ['remove_button'],
    delimiter: ',',
    persist: false
	});

  $(window).on('scroll', function() {
    if($(document).scrollTop() > 200) {
      $('body').addClass('hideHeader');
    } else {
      $('body').removeClass('hideHeader');
    }
  })



	var $select =$('.select').selectize({
	});

	// DatePicker
	$('[data-toggle="month"]').datepicker({
		format: 'mm'
	});
	$('[data-toggle="year"]').datepicker({
		format: 'yyyy'
	});

    // Sub header add Active class
    $(".SubHeaderUl li a").on('click', function(){
        $(".SubHeaderUl li a").removeClass('is_Active');
        $(this).addClass("is_Active");
    })

    $('.HomeSlider').slick({
        lazyLoad: 'ondemand',
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
        // easing: ''
    });

    var setFn;

    function showModal() {
     setFn = setTimeout(function () {
      if(localStorage.getItem("cookieStep") && localStorage.getItem("cookieStep") == 1){
        return
      }
      console.log('hello')
      $('.CookiesBox').css('display', 'block');
      }, 1000)
    }
    showModal();

    $('#cookieClose').click(function(e) {
      e.preventDefault()
      // console.log($(this), $('this'), $(this).parent())
      $(this).parent().css('display', 'none');
      localStorage.setItem("cookieStep", 1) 
        // clearTimeout(setFn);
    })
    // localStorage.removeItem("cookieStep")
//---Custome Select box
function create_custom_dropdowns() {
  $(".customeSelectWrap select").each(function(i, select) {
    if (!$(this).next().hasClass('customeDropdown')) {
      $(this).after('<div class="customeDropdown ' + ($(this).attr('class') || '') + '" tabindex="0"><span class="current"></span><div class="list"><ul></ul></div></div>');
      var customeDropdown = $(this).next();
      var options = $(select).find('option');
      var selected = $(this).find('option:selected');
      customeDropdown.find('.current').html(selected.data('display-text') || selected.text());
      options.each(function(j, o) {
        var display = $(o).data('display-text') || '';
        customeDropdown.find('ul').append('<li class="option ' + ($(o).is(':selected') ? 'selected' : '') + '" data-value="' + $(o).val() + '" data-display-text="' + display + '">' + $(o).text() + '</li>');
      });
    }
  });
}

// Event listeners

// Open/close
    $(document).on('click', '.customeDropdown', function(event) {
      $('.customeDropdown').not($(this)).removeClass('open');
      $(this).toggleClass('open');
      if ($(this).hasClass('open')) {
        $(this).find('.option').attr('tabindex', 0);
        $(this).find('.selected').focus();
      } else {
        $(this).find('.option').removeAttr('tabindex');
        $(this).focus();
      }
    });
    // Close when clicking outside
    $(document).on('click', function(event) {
      if ($(event.target).closest('.customeDropdown').length === 0) {
        $('.customeDropdown').removeClass('open');
        $('.customeDropdown .option').removeAttr('tabindex');
      }
      event.stopPropagation();
    });
    // Option click
    $(document).on('click', '.customeDropdown .option', function(event) {
      $(this).closest('.list').find('.selected').removeClass('selected');
      $(this).addClass('selected');
      var text = $(this).data('display-text') || $(this).text();
      $(this).closest('.customeDropdown').find('.current').text(text);
      $(this).closest('.customeDropdown').prev('select').val($(this).data('value')).trigger('change');
    });

    // Keyboard events
    $(document).on('keydown', '.customeDropdown', function(event) {
      var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
      // Space or Enter
      if (event.keyCode == 32 || event.keyCode == 13) {
        if ($(this).hasClass('open')) {
          focused_option.trigger('click');
        } else {
          $(this).trigger('click');
        }
        return false;
        // Down
      } else if (event.keyCode == 40) {
        if (!$(this).hasClass('open')) {
          $(this).trigger('click');
        } else {
          focused_option.next().focus();
        }
        return false;
        // Up
      } else if (event.keyCode == 38) {
        if (!$(this).hasClass('open')) {
          $(this).trigger('click');
        } else {
          var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
          focused_option.prev().focus();
        }
        return false;
      // Esc
      } else if (event.keyCode == 27) {
        if ($(this).hasClass('open')) {
          $(this).trigger('click');
        }
        return false;
      }
    });

    $(document).ready(function() {
      create_custom_dropdowns();
    });

 $(document).on('change','.up', function(){
  var id = $(this).attr('id');
   var profilePicValue = $(this).val();
   console.log("File", profilePicValue);
   var fileNameStart = profilePicValue.lastIndexOf('\\');
   profilePicValue = profilePicValue.substr(fileNameStart + 1).substring(0,20);
   if (profilePicValue != '') {

      $(this).closest('.fileUpload').find('.upl').html(profilePicValue);
   }
 });
//---- End File Upload

});
// End document.ready


	// Passing a selector and an options object. [UIKit - Sticky]
	let options = {
		offset: 220,
		animation: 'uk-animation-slide-top',
		media: 1046

	}

	// var sticky = UIkit.sticky('#FilterSidebar', options);


// tinymce editor-----
// tinymce.init({
//     selector: '.tinymceEditor',
//     // height: 200,
//     menubar: false,
//     plugin: [
//         'advlist autolink lists link image charmap print preview anchor textcolor',
//         'searchreplace visualblocks code fullscreen',
//         'insertdatetime media table contextmenu paste code help wordcount'
//     ],
//     toolbar: 'insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
//     content_css: [
//         '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
//     ]
// });
// End tinymce editor-----//

//-- Add Upload Photo and Remove Upload Photo
function CloneDiv(){
    var clone = document.querySelector('.UploadPhotoFormContainer').cloneNode(true);
    document.getElementById("photoUploadMain").appendChild(clone);
}

function Delete(button){
    var parent = button.parentNode;
    // console.log("Parent:", parent);
    var grand_father = parent.parentNode;
    // console.log("Grand Parent:", grand_father);
    grand_father.removeChild(parent);
}

// var AddPhoto = document.getElementById('AddPhoto');
// var RemovePhoto = document.querySelector('.RemovePhoto');

// AddPhoto.addEventListener('click', function(e){
//     e.preventDefault();
//     CloneDiv();
// });

// Custome Select Box

//---- File Upload
var fileTypes = ['pdf', 'docx', 'rtf', 'jpg', 'jpeg', 'png', 'txt'];
function readURL(input) {
    if (input.files && input.files[0]) {
        var extension = input.files[0].name.split('.').pop().toLowerCase(),
            isSuccess = fileTypes.indexOf(extension) > -1;

        if (isSuccess) { //yes
            var reader = new FileReader();
            reader.onload = function (e) {
                if (extension == 'pdf'){
                  $(input).closest('.fileUpload');
                }
                else if (extension == 'docx'){
                  $(input).closest('.fileUpload');
                }
                else if (extension == 'rtf'){
                  $(input).closest('.fileUpload');
                }
                else if (extension == 'png'){ $(input).closest('.fileUpload');
                }
                else if (extension == 'jpg' || extension == 'jpeg'){
                  $(input).closest('.fileUpload');
                }
              else if (extension == 'txt'){
                  $(input).closest('.fileUpload');
                }
                else {
                  //console.log('here=>'+$(input).closest('.uploadDoc').length);
                  $(input).closest('.uploadDoc').find(".docErr").slideUp('slow');
                }
            }

            reader.readAsDataURL(input.files[0]);
        }
        else {
            //console.log('here=>'+$(input).closest('.uploadDoc').find(".docErr").length);
            $(input).closest('.uploadDoc').find(".docErr").fadeIn();
            setTimeout(function() {
            $('.docErr').fadeOut('slow');
          }, 9000);
        }
    }
}
$(document).ready(function(){
    $(this).scrollTop(0);
});
















